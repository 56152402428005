import axios from 'axios';

class TraduoraBackend {
    constructor(services, backendOptions, i18nextOptions ) {
        this.traduora = {};
        this.token = undefined;
        this.init(services, backendOptions, i18nextOptions);
    }

    init(services = {}, backendOptions = {}, { traduora } = {}) {
        this.traduora = traduora;
        this.token = undefined;
    }

    async getToken(){
        const { endpoint, client_id, client_secret } = this.traduora;
        const response = await axios.post(endpoint+"/api/v1/auth/token",
            {
                grant_type:'client_credentials',
                client_id,
                client_secret,
            });
        this.token = response.data.access_token;
    }

    async read(locale, namespace, callback) {
        const { endpoint, project_id } = this.traduora;
        try {
            if (!this.token) await this.getToken();
            const response = await axios.get(`${endpoint}/api/v1/projects/${project_id}/exports`,{
                headers:{Authorization: `Bearer ${this.token}`},
                params: {
                    locale,
                    format: "jsonflat",
                },
            });
            callback(null, response.data);
        } catch (error) {
            callback(error, null);
        }
    }

    async create(languages, namespace, key, fallbackValue) {
        const { endpoint, project_id } = this.traduora;
        try {
            if (!this.token) await this.getToken();
            await axios.post(`${endpoint}/api/v1/projects/${project_id}/terms`, {
                value: key
            }, {
                headers:{Authorization: `Bearer ${this.token}`},
            });
        } catch (error) {
        }
    }
}

TraduoraBackend.type = "backend";

export default TraduoraBackend;
