export default {
  name: 'myNavigator',

  lookup(options) {
    let found = [];

    if (typeof navigator !== 'undefined') {
      if (navigator.languages) { // chrome only; not an array, so can't use .push.apply instead of iterating
        for (let i=0; i < navigator.languages.length; i++) {
          found.push(navigator.languages[i].substring(0,2));
        }
      }
      if (navigator.userLanguage) {
        found.push(navigator.userLanguage.substring(0,2));
      }
      if (navigator.language) {
        found.push(navigator.language.substring(0,2));
      }
    }

    return found.length > 0 ? found : undefined;
  }
};
