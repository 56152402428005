import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import * as serviceWorker from './serviceWorker';
import 'utils/polyfills';
import './i18n';

function importBuildTarget() {
  switch (process.env.REACT_APP_BUILD_TARGET) {
    case 'chat':
      return import('./IndependentChat');
    case 'app':
    default:
      return import('./App');
  }
}

// Import the entry point and render it's default export
importBuildTarget().then(({default: Environment}) => {
  ReactDOM.render(
    <Suspense fallback={''}>
      <Environment />
    </Suspense>,
    document.getElementById('root'),
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
