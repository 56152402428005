// In case client does not have localStorage, use simple in memory storage
// That means no persistence after refresh for old browsers
(function localStoragePolyFill() {
  const isStorage = (function isStorage() {
    try {
      return 'localStorage' in window && window.localStorage !== null;
    } catch (e) {
      return false;
    }
  })();

  if (!isStorage) {
    let data = {};
    window.localStorage = {
      setItem: (id, val) => {
        data[id] = String(val);
        return data[id];
      },
      getItem: id => (Object.prototype.hasOwnProperty.call(data, id) ? data[id] : undefined),
      removeItem: id => delete data[id],
      clear: () => {
        data = {};
        return data;
      },
    };
  }
})();
