import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'utils/TraduoraBackend';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/es';
import myLocalStorageDetector from 'utils/myLocalStorageDetector';
import myNavigatorDetector from 'utils/myNavigatorDetector';
const languageDetector = new LanguageDetector();
languageDetector.addDetector(myLocalStorageDetector);
languageDetector.addDetector(myNavigatorDetector);

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      detection: {
        order: ['myLocalStorage', 'myNavigator'],
      },
      fallbackLng: 'fr',
      debug: false,
      saveMissing: true,
      returnEmptyString: false,
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false, // react already safes from xss
        format: function(value, format, lng) {
          if (format === 'uppercase') return value.toUpperCase();
          if (format === 'lowercase') return value.toLowerCase();

          if (value instanceof Date) return moment(value).format(format);
          return value;
        },
      },
      traduora: {
        endpoint: 'https://traduora.cuult.fr',
        project_id: 'd4f8e005-c629-4c1e-ba3f-876cc47b41d0',
        client_id: 'bacd6d6a-ff44-4714-8f05-36fa718007a5',
        client_secret: '65hewHpIyq961jQjhu4No6PLDRTaz6An',
      },
    },
    err => {
      if (err) return;
      moment.locale(i18n.language);

      // FIX: For some reason, moment locale reset after initialization.
      // So we have to set it again after 1 second
      setTimeout(() => {
        moment.locale(i18n.language);
      }, 1000);
    },
  );

i18n.on('languageChanged', function(lng) {
  moment.locale(lng);
});

export default i18n;
